import Metadata from '../components/Metadata';
import Navigation from '../components/Navigation';
import React from 'react';

export default function Home() {
  return (
    <>
      <Metadata title="Software Engineer & Product Manager" />
      <div>
        Home
        <Navigation />
      </div>
    </>
  );
}
